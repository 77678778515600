<template>
  <div style="height: calc(100% - 64px);" class="d-flex justify-center align-center">
    <div
      class="d-flex flex-column text-center"
    >
      <div>
        <v-icon
          v-if="!folderData.docId || !folderData.info.icon"
          size="7vmax"
        >
          {{ folderData.icon || 'mdi-folder' }}
        </v-icon>
        <template
          v-else
        >
          <ionic-icon 
            :icon="folderData.info.icon"
            size="7vmax"
            :style="{
              color: folderData.info.icon && folderData.info.icon.color ? folderData.info.icon.color : 'var(--main-dark-color)'
            }"
          />
        </template>
      </div>
      <div>
        <span class="display-1" style="font-size: 1.3vmax !important;">
          No documents yet
        </span>
        <p
          class="mt-5"
          style="font-size: 1.4vmax !important;"
        >
          Tap the "+" button to get started
        </p>
      </div> 
    </div>
  </div>
</template>
<script>
import IonicIcon from '@/components/ionic-icons/icon'

export default {
  props: {
    folderData: {
      required: true
    }
  },
  name: 'FolderIsEmptyPlaceholder',
  components: {
    IonicIcon
  }
}
</script>